<template>
  <layout width="420">
    <template slot="header">
      Add geo group
    </template>

    <template>
      <v-form>
        <v-select
          v-model="state.geoId"
          :items="state.geotags"
          item-text="name"
          item-value="id"
          label="Geo group name"
        />
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="exec"
      >
        Add
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, onMounted } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { objectService } from '@/modules/objects/api';
import { geotagsService } from '@/modules/geotags/api';
import { abcSort } from '@/compositions/sortBy';

export default {
  name: 'GeoObjectAdd',
  components: {
    Layout
  },
  props: {
    objectId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const state = ref({
      geoId: '',
      geotags: []
    });

    onMounted(async () => {
      const geotags = await geotagsService.fetch();
      state.value.geotags = geotags.sort((a, b) => abcSort(a.name, b.name));
    });

    const submit = async () => {
      await objectService.link(props.objectId, state.value.geoId);
      emit('confirm');
      emit('close');
    };

    const { loading, exec } = usePromise(submit, true);

    return {
      state,
      exec,
      loading
    };
  }
};
</script>
